@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Moderustic:wght@300..800&family=Nerko+One&family=Pirata+One&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Macondo&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --secondary-color: #2b2b2a;
  --text-color: #2b2b2a;
  --orange-color: #f4a261;
  --font-family-normal: "Moderustic", sans-serif;
  --font-family-heading: "Nerko One", cursive;
  --font-family-stylish-heading: "Baskervville SC", serif;
  --logo--font: "Baskervville SC", serif;
  --logo-font--two: "Macondo", cursive;
}
/* for stylish headings:
-use: font-family: "Baskervville SC", serif; */

body {
  font-family: var(--font-family-normal);
}
p {
  font-family: var(--font-family-normal);
}
.ant-table-thead > tr > th {
  font-size: 22px;
  font-family: "Moderustic", sans-serif;
  font-weight: 400 !important;
}
.hero-small-text {
  font-family: var(--font-family-normal);
  font-weight: 500;
  color: rgb(100, 100, 100);
}
.custom-back-top {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 8px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-back-top:hover {
  background-color: #0a6fb5;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.span-text {
  color: var(--orange-color);
}
.hero-title {
  font-size: 74px;
  margin: 20px 0;
  font-family: var(--font-family-stylish-heading);
  max-width: 1200px;
}
.preloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.main-content {
  text-align: center;
  padding: 20px;
}
.hero-description {
  font-size: 30px !important;
  max-width: 900px;
  margin-top: 20px;
}
.hero-caption {
  font-family: "Moderustic", sans-serif;
  font-size: 30px !important;
  max-width: 900px;
  margin-top: 20px;
}
.blog-card {
  height: 300px;
  display: flex;
  justify-content: space-between;
}
.google-login-button {
  background: #ffffff;
  border-color: #d9d9d9;
  color: #757575;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  font-size: 16px;
  width: auto;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: background 0.3s, box-shadow 0.3s;
}

.google-login-button:hover {
  background: #f5f5f5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.google-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* CONTACT FORM */

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.contact-form {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #1890ff;
  outline: none;
  box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #1890ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #007acc;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-form {
    padding: 15px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 14px;
  }

  .submit-btn {
    font-size: 14px;
  }
}
