.admin-login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-form-button {
  width: 100%;
}

.login-form-forgot {
  float: right;
}

.site-form-item-icon {
  color: rgba(0, 0, 0, 0.25);
}
