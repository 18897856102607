.footer {
  /* background-color: #333; */
  color: #fff;
  padding: 20px 0;
  text-align: center;
  font-size: 1.3rem;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-text {
  margin-bottom: 10px;
  font-size: 1.8rem;
  color: #ffffff;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-icons a {
  color: #fff;
  font-size: 1.6rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #00bfa6; /* Adjust hover color */
}

.contact-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #00bfa6;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.contact-button:hover {
  background-color: #009688;
}

@media (max-width: 768px) {
  .social-icons {
    gap: 15px;
  }

  .footer-text {
    font-size: 1rem;
  }

  .contact-button {
    padding: 8px 16px;
  }
  .footer {
    font-size: 0.8rem;
  }
}
