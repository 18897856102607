.animated-icon {
  font-size: 36px;
  color: #0369a1;
  transition: transform 0.3s ease, color 0.3s ease;
}

.animated-icon:hover {
  transform: translateY(-3px);
  color: #00aaff;
}
.card-header-custom .ant-card-head {
  background-color: #e0f2f1;
  color: #00796b;
}

.card-header-custom .ant-card-head-title {
  color: #00796b;
}
.card-custom .ant-card-head {
  background-color: #e0f2f1;
  color: #00796b;
}

.card-custom .ant-card-head-title {
  color: #00796b;
}

.company-form .form-label {
  display: block;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
}

.company-form .ant-input {
  border-radius: 4px;
}

.submit-button {
  margin-top: 16px;
  width: 100%;
}
.card-custom {
  border: 1px solid #00796b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.delete-company-card {
  border: 2px solid #f5222d;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.delete-company-card .ant-card-body {
  padding: 24px;
}

.delete-company-card .ant-typography {
  color: #333;
}

.delete-company-card .ant-btn-primary {
  background-color: #f5222d;
  border-color: #f5222d;
}

.delete-company-card .ant-btn-default {
  border-color: #d9d9d9;
}

.logo-admin {
  height: 32px;
  width: 210;
  margin: 16px;
  border-radius: 5px;
}
.logo-image {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}
.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-top: 20px;
}
