.theme-switcher {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

body.light {
  background-color: #e0f2f1;
  color: #333;
}

body.dark {
  background-color: #333;
  color: #e0f2f1;
}
